<template>
  <div class="row">
    <div class="col-12 col-lg-4 c g text-center" v-if="!loaded">
        <div class="card card-body text-center">
            <img :src="require('@/assets/images/loading.svg')" class="c" style="width: 150px; height: 150px;">
        </div>
    </div>
    <div class="col-12 col-lg-4 g c" v-if="loaded">
        <div class="card">
            <div class="card-header">
                <div v-if="!info.title">
                    <div class="row">
                        <div class="form-groupd col-12 col-lg-8">
                            <label for="">اسم الجهاز</label>
                            <input type="text"
                                class="form-control" v-model="title" placeholder="مثال: الجهاز رقم 1">
                        </div>
                        <div class="form-groupd col-12 col-lg-4">
                            <br>
                            <button class="btn btn-primary" style="margin-top: 2px" @click="addDevice()">
                                اضافة الجهاز
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="info.title" class="col-12 text-center">
                    <img :src="qrcode" v-if="!qrcodem()" class="c" style="width: 200px; height: 200px; border: solid 4px red">
                    <img :src="qrcodem()" v-if="qrcodem()" class="c" style="width: 200px; height: 200px; border-radius: 10px; border: solid 4px lightgreen">
                </div>
            </div>
            <div class="card-footer">
                <div class="alert alert-danger text-center g" v-if="error" v-html="error"></div>
                <button class="btn btn-block border text-danger btn-sm" v-if="info.status == 2 || info.status == 3" @click="deleteDevice()">
                    <i class="fa fa-trash"></i>
                    حذف الجهاز واضافته من جديد
                </button>
                <div class="alert alert-success text-center g" v-if="success">
                    {{ success }}
                </div>
                <div class="row" v-if="success">
                    <div class="col-12 col-lg-6">
                        <button class="btn btn-block border text-danger btn-sm" @click="deleteDevice()">
                            <i class="fa fa-trash"></i>
                            حذف الجهاز
                        </button>
                    </div>
                    <div class="col-12 col-lg-6">
                        <button class="btn btn-block border text-warning btn-sm" @click="restart()">
                            <i class="fa fa-refresh"></i>
                            اعادة تشغيل
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
var QRCode = require("qrcode");
export default {
    data(){
        return {
            info: {},
            title: "",
            user: JSON.parse(localStorage.getItem('user')),
            interval: null,
            error: null,
            loaded: false,
            success: false,
            qrcode: ""
        }
    },
    beforeDestroy(){
        clearInterval(this.interval)
    },
    created(){
        if(!checkPer("whatsapp")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.fetchInfo()
        g.interval = setInterval(() => {
            g.fetchInfo()
        }, 3000);
    },
    methods: {
        fetchInfo(){
            var g = this;
            $.post(api + '/user/whatsapp/info', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loaded = true
                if(r.status != 100){
                    g.error = r.response
                    g.success = null
                }else{
                    g.error = null
                    g.success = null
                    g.info = r.response
                    if(g.info.status == 1){
                        g.success = "تم ربط الجهاز بنجاح"
                    }
                    if(g.info.status == 2){
                        g.error = "الجهاز قطع اتصاله"
                    }
                    if(g.info.status == 3){
                        g.error = "الجهاز جديد، ربما يتم حظره٫ تواصل مع الدعم الفني لمزيد من التفاصيل."
                    }
                }
            }).fail(function(){
                g.loaded = true
            })
        },
        addDevice(){
            var g = this;
            g.loaded = false;
            $.post(api + '/user/whatsapp/add', {
                jwt: this.user.jwt,
                title: g.title
            }).then(function(r){
                g.fetchInfo()
            }).fail(function(){
                g.loaded = true
            })
        },
        qrcodem(){
            var g = this;
            var device = g.info;
            if(device.qrcode == 'https://cdn-static.brmja.com/storage/uploads/images/638a2ca47233f227205132.png' && device?.info?.image?.includes("whatsapp")){
                return device?.info?.image;
            }
            if(device.qrcode.includes('https')){
                return device.qrcode
            }
            if(!device.qrcode.includes('https')){
                g.error = "قم بفحص الرمز الان من داخل تطبيق الواتس اب <a target='_blank' href='https://youtu.be/Nw0Wc-WAT7Y?si=sqMIgMhbvh_GnLy-&t=14'>للتوضيح اضغط هنا</a>"
                QRCode.toDataURL(
                    device.qrcode
                ).then(function(r){
                    g.qrcode = r
                })
                return false;
            }
        },
        deleteDevice(){
            var g = this;
            if(confirm("متأكد من حذف الجهاز؟")){
                g.loaded = false;
                $.post(api + '/user/whatsapp/delete', {
                    jwt: this.user.jwt,
                }).then(function(r){
                    g.fetchInfo()
                }).fail(function(){
                    g.loaded = true
                })
            }
        },
        restart(){
            var g = this;
            if(confirm("متأكد من اعادة تشغيل الجهاز؟")){
                g.loaded = false;
                $.post(api + '/user/whatsapp/restart', {
                    jwt: this.user.jwt,
                }).then(function(r){
                    g.fetchInfo()
                }).fail(function(){
                    g.loaded = true
                })
            }
        }
    }
}
</script>

<style>

</style>